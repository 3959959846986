import { Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FC, ReactNode, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "redux_config/hooks";
import Sidebar from "./Sidebar";

const Layout: FC<ReactNode & { publicRoute?: boolean }> = ({
  children,
  publicRoute = false,
}) => {
  const [open, setOpen] = useState<boolean>(true);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const { mode } = useAppSelector((state) => state.theme);

  const isLargeScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.up("md")
  );

  useLayoutEffect(() => {
    if (!isLargeScreen && open) {
      setOpen(false);
    } else {
      if (isLargeScreen && !open) {
        setOpen(true);
      }
    }
    // eslint-disable-next-line
  }, [isLargeScreen]);

  const { i18n } = useTranslation();
  const drawerWidth =
    i18n.language === "fr" ? 315 : i18n.language === "ja" ? 340 : 280;

  return (
    <Box
      style={{ backgroundColor: "gray" }}
      sx={{ display: "flex", overflowX: "hidden" }}
    >
      <CssBaseline />

      {!publicRoute && isLargeScreen ? (
        <Sidebar
          open={open}
          drawerWidth={drawerWidth}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
      ) : null}
      <Paper
        component="main"
        sx={{
          p: 3,
          flexGrow: 1,
          height: "100vh",
          overflowX: "hidden",
          backgroundColor: mode === "light" ? "#f3f3f3" : "black",
        }}
      >
        <Grid>{children}</Grid>
      </Paper>
    </Box>
  );
};

export default Layout;
