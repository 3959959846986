import { ThemeProvider } from "@mui/material/styles";
import "core/assets/css/global.css";
import { muiToggle } from "core/theme/muiTheme";
import AppMain from "GenerateRoutes";
import { queryClient } from "QueryClient.config";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppSelector } from "redux_config/hooks";
  
export const App = () => {
  const { mode } = useAppSelector((state) => state.theme);

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer
        draggable
        limit={1}
        rtl={false}
        closeOnClick
        pauseOnHover
        autoClose={5000}
        pauseOnFocusLoss
        newestOnTop={false}
        position="top-center"
        hideProgressBar={false}
      />
      <ThemeProvider theme={muiToggle(mode)}>
        <BrowserRouter>
          <AppMain />
        </BrowserRouter>
      </ThemeProvider>
      <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
    </QueryClientProvider>
  );
};
