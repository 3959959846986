import { Preview, RateReview } from "@mui/icons-material";
import { DATA_REVIEW_MODAL, LABELING_MODEL } from "Pages/routes/constants";
import { TFunctionArg } from "types/global";

export const sidebarList: sidebarListType = (t) => [
  {
    id: 1,
    link: LABELING_MODEL,
    icon: <RateReview htmlColor="#fff" />,
    label: "Labeling Model",
    childList: [],
  },
  {
    id: 2,
    link: DATA_REVIEW_MODAL,
    icon: <Preview htmlColor="#fff" />,
    label: "Data Review Model",
    childList: [],
  },
];

type sidebarListType = (t: TFunctionArg) => {
  id: number;
  icon: any;
  link: string;
  label: string;
  childList: {
    id: number;
    icon: any;
    link: string;
    label: string;
  }[];
}[];
