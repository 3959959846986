import Page404 from "Pages/404";
import React, { Suspense } from "react";
import Layout from "core/layout/Layout";
import { PageLoader } from "./core/components";
import { Redirect, Route, Switch } from "react-router-dom";
import { PublicRoute, publicRoutes } from "Pages/routes/public";
import { PrivateRoute, protectedRoutes } from "Pages/routes/private";

const renderPublicRoutes = (() =>
  publicRoutes.map((Obj, index) => (
    <PublicRoute
      exact={Obj.exact}
      path={Obj.path}
      component={Obj.component}
      key={index}
    />
  )))();

const renderProtectedRoutes = (() =>
  protectedRoutes.map((Obj, index) => (
    <PrivateRoute
      path={Obj.path}
      component={Obj.component}
      key={index}
      exact={Obj.exact}
    />
  )))();

const Routes = () => {
  return (
    <Switch>
      {renderPublicRoutes}
      <Layout>{renderProtectedRoutes}</Layout>
      <Route path="*" component={Page404} />
    </Switch>
  );
};

export const AppMain = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from="/" to="/label-model/" />

        <Route path="/" component={Routes} />
        <Route path="*" component={Page404} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppMain);
