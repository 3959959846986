import { FC, MouseEvent } from "react";
import { visuallyHidden } from "@mui/utils";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { Box, Checkbox, TableSortLabel } from "@mui/material";
import { useAppSelector } from "redux_config/hooks";

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { mode } = useAppSelector((state) => state.theme);

  const style = {
    headRow: {
      minHeight: 50,
    },
    headCell: {
      fontWeight: "bold",
      backgroundColor: mode === "light" ? "#e5e5e5" : "",
    },
  };

  const { headCells, order, orderBy, onRequestSort, numSelected, rowCount, onSelectAllClick, multiSelectRow } = props;
  const createSortHandler = (property: any) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const RenderCells: FC<RenderCell> = ({ headCell, i }) => {
    if (headCell.sortable) {
      return (
        <TableCell
          key={i}
          style={style.headCell}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={orderBy === headCell.field ? order : false}
        >
          <TableSortLabel
            key={i}
            active={orderBy === headCell.field}
            onClick={createSortHandler(headCell.field)}
            direction={orderBy === headCell.field ? order : "desc"}
          >
            {headCell.headerName}
            {orderBy === headCell.field ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      );
    }

    if (headCell?.isChild) {
      return (
        <>
          <TableCell style={{ backgroundColor: mode === "light" ? "#e5e5e5" : "" }}>
            <div
              style={{
                fontSize: 15,
                textAlign: "center",
                fontWeight: "bolder",
                backgroundColor: mode === "light" ? "#e5e5e5" : "",
              }}
            >
              {headCell?.headerName}
            </div>
            <div style={{ border: "0px", textAlign: "center", display: "flex", justifyContent: "space-between" }}>
              {headCell?.childColumns?.map((item: any, i: number) => (
                <span key={i} style={{ fontWeight: "bolder", fontSize: "12px", marginRight: "10px" }}>
                  {item}
                </span>
              ))}
            </div>
          </TableCell>
        </>
      );
    }
    return (
      <TableCell
        key={i}
        style={{ fontWeight: "bolder", fontSize: 15, backgroundColor: mode === "light" ? "#e5e5e5" : "" }}
      >
        {headCell.headerName}
      </TableCell>
    );
  };

  return (
    <TableHead style={{ ...style.headRow, whiteSpace: "nowrap" }}>
      {multiSelectRow && (
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
      )}
      {headCells.length > 0 && headCells.map((headCell, i) => <RenderCells key={i} headCell={headCell} i={i} />)}
    </TableHead>
  );
};

type EnhancedTableProps = {
  order: any;
  orderBy: any;
  headCells: any[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  multiSelectRow: boolean;
};
type RenderCell = {
  headCell: {
    field?: string;
    isChild: boolean;
    childColumns: any;
    sortable?: boolean;
    headerName?: string;
    disablePadding?: boolean;
  };
  i: number;
};

export default EnhancedTableHead;
