import { ExpandLess, ExpandMore } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Collapse,
  Drawer as MuiDrawer,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { clearAppTokensAndRedirect } from "core/utils";
import { Fragment, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { sidebarList } from "./Sidebar.constant";
import { sidebarList as adminSidebarList } from "./Sidebar.admin.constant";
import Toggle from "./Toggle";
import { useLocalStorage } from "react-use";

const useStyles = makeStyles({
  paper: {
    background: "#000",
  },
  divider: {
    background: "#fff",
  },
  listItem: {
    "&:hover": {
      background: "#5c5c5c",
    },
  },
});

const disabledLink = [];

const Sidebar = ({
  t,
  open,
  drawerWidth,
  handleDrawerOpen,
  handleDrawerClose,
}) => {
  const { pathname } = useLocation();

  // const { mode } = useAppSelector((state) => state.theme);
  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(7)} + 6px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const ChildList = ({ menuOpen, childList }) => (
    <Collapse in={menuOpen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {childList.map(({ id, label, link, icon }) => (
          <ListItem
            button
            key={id}
            to={link}
            component={NavLink}
            className={classes.listItem}
            sx={{ pl: 7 }}
            disabled={disabledLink.includes(link)}
            activeStyle={{
              backgroundColor: pathname.includes(link) && "#0288d1",
            }}
          >
            <ListItemIcon>
              <img
                alt="icon"
                src={icon}
                width="20px"
                height="20px"
                style={{ marginLeft: 5, marginRight: 25 }}
              />
            </ListItemIcon>
            <ListItemText primary={label} style={{ color: "#fff" }} />
          </ListItem>
        ))}
      </List>
    </Collapse>
  );

  const classes = useStyles();
  const sideList = sidebarList(t);
  const adminSideList = adminSidebarList(t);

  const [menuOpen, setMenuOpen] = useState({
    cryptoMenu: true,
    nftMenu: false,
  });

  useEffect(() => {
    if (!open) {
      setMenuOpen({ cryptoMenu: false, nftMenu: false });
    } else {
      //? filter side menu list to only show crypto or nft based on the current route
      const currentRoute =
        window.location.pathname.split("/").length > 1 &&
        window.location.pathname.split("/")[1];
      if (currentRoute === "nft") {
        setMenuOpen({ cryptoMenu: false, nftMenu: true });
      } else {
        setMenuOpen({ cryptoMenu: true, nftMenu: false });
      }
    }
  }, [open]);

  const handleMouseEnter = (event, link, id) => {
    link === "/" && event.preventDefault();
    id === 1 &&
      setMenuOpen({
        ...menuOpen,
        cryptoMenu: !open ? false : !menuOpen.cryptoMenu,
      });
    id === 2 &&
      setMenuOpen({ ...menuOpen, nftMenu: !open ? false : !menuOpen.nftMenu });
    if ([1, 2].includes(id) && !open) {
    }
  };

  const [value] = useLocalStorage("is_admin");

  return (
    <Drawer variant="permanent" open={open} classes={{ paper: classes.paper }}>
      {/* <DrawerHeader>
        <img
          style={{ width: "90%", height: "auto", margin: "auto" }}
          src={logo}
          alt="logo"
        />
      </DrawerHeader> */}
      <List>
        {value
          ? adminSideList.map(({ id, label, link, icon, childList }) => {
              return (
                <Fragment key={id}>
                  <ListItem
                    button
                    component={NavLink}
                    className={classes.listItem}
                    to={link !== "/" ? link : "/"}
                    disabled={disabledLink.includes(link)}
                    onClick={(e) => handleMouseEnter(e, link, id)}
                    activeStyle={{
                      backgroundColor:
                        (!link === "/" ||
                          link === window?.location?.pathname) &&
                        "#0288d1",
                    }}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={label} style={{ color: "#fff" }} />
                    {childList.length > 0 && (
                      <>
                        {menuOpen[id === 1 ? "cryptoMenu" : "nftMenu"] ? (
                          <ExpandLess sx={{ color: "#fff" }} />
                        ) : (
                          <ExpandMore sx={{ color: "#fff" }} />
                        )}
                      </>
                    )}
                  </ListItem>

                  {childList.length > 0 && (
                    <ChildList
                      menuOpen={
                        id === 1 ? menuOpen.cryptoMenu : menuOpen.nftMenu
                      }
                      childList={
                        sideList.length > 0 && id
                          ? sideList[id - 1]?.childList
                          : []
                      }
                    />
                  )}
                </Fragment>
              );
            })
          : sideList.map(({ id, label, link, icon, childList }) => {
              return (
                <Fragment key={id}>
                  <ListItem
                    button
                    component={NavLink}
                    className={classes.listItem}
                    to={link !== "/" ? link : "/"}
                    disabled={disabledLink.includes(link)}
                    onClick={(e) => handleMouseEnter(e, link, id)}
                    activeStyle={{
                      backgroundColor:
                        (!link === "/" ||
                          link === window?.location?.pathname) &&
                        "#0288d1",
                    }}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={label} style={{ color: "#fff" }} />
                    {childList.length > 0 && (
                      <>
                        {menuOpen[id === 1 ? "cryptoMenu" : "nftMenu"] ? (
                          <ExpandLess sx={{ color: "#fff" }} />
                        ) : (
                          <ExpandMore sx={{ color: "#fff" }} />
                        )}
                      </>
                    )}
                  </ListItem>

                  {childList.length > 0 && (
                    <ChildList
                      menuOpen={
                        id === 1 ? menuOpen.cryptoMenu : menuOpen.nftMenu
                      }
                      childList={
                        sideList.length > 0 && id
                          ? sideList[id - 1]?.childList
                          : []
                      }
                    />
                  )}
                </Fragment>
              );
            })}

        <ListItem
          button
          className={classes.listItem}
          onClick={clearAppTokensAndRedirect}
          style={{
            textAlign: "center",
          }}
        >
          <ListItemIcon>
            <LogoutIcon style={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText
            primary={open ? "Logout" : ""}
            style={{ color: "#fff" }}
          />
        </ListItem>
      </List>
      <div style={{ position: "absolute", top: "50%", right: 16 }}>
        <Toggle
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
      </div>
    </Drawer>
  );
};

export default withTranslation()(Sidebar);
