import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "redux_config/slices/themeSlice";
import insightsReducer from "redux_config/slices/insights";
import comparisonReducer from "redux_config/slices/comparisonSlice";
import walletReducer from "redux_config/slices/walletSlice";

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    comparison: comparisonReducer,
    insights: insightsReducer,
    wallet: walletReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
