import { FC, ReactNode } from "react";
import { TextFieldProps } from "@mui/material";
import { MuiInput } from "../material/MuiInput";
import { Field, FormikErrors, FormikTouched, useFormikContext } from "formik";

/**
 *
 * @author Muhammad Raheel
 * @category Components
 * @created Jan 13, 2022
 * @description Input with formik validations
 * @returns This function returns ReactElement
 */

export const FormikInput: FC<FormikInputPropTypes> = ({ name, icon, ...restProps }) => {
  const { errors, touched } = useFormikContext<FormikErrors<string> & FormikTouched<boolean>>();

  return (
    <Field
      name={name}
      icon={icon}
      as={MuiInput}
      helperText={touched[name!] && errors[name!]}
      error={errors[name!] && Boolean(touched[name!])}
      {...restProps}
    />
  );
};

/**
 * @category Types
 * @author Muhammad Raheel
 * @created Jan 13, 2022
 * @link TextFieldProps API Docs: https://mui.com/api/text-field/
 * @link Formik Field API Docs: https://formik.org/docs/api/field
 */

export type FormikInputPropTypes = {
  icon?: ReactNode;
  required_field?: boolean;
} & TextFieldProps;
