import { createTheme } from "@mui/material/styles";

export const muiToggle = (mode = "light") =>
  createTheme({
    palette: {
      primary: {
        main: "#0288d1",
        light: "#29b6f6",
        dark: "#0288d1",
        contrastText: mode === "light" ? "#000" : "#fff",
      },
      secondary: {
        main: "#000",
        light: "#1A1A1A",
        dark: "#1A1A1A",
      },
      // mode: mode,
    },
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            marginBottom: "20px !important",
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            paddingBottom: 0,
          },
        },
      },
    },
  });
