import { createSlice } from "@reduxjs/toolkit";

type InitialStateType = {
  collection: {
    slug: string;
    name: string;
    collection_address: string;
  };
};

const initialState: InitialStateType = {
  collection: {
    slug: "0n1-force'",
    name: "0N1 Force",
    collection_address: "0x3bf2922f4520a8ba0c2efc3d2a1539678dad5e9d",
  },
};

export const collectionInsights = createSlice({
  name: "radioValue",
  initialState,
  reducers: {
    changeCollection: (state, action) => {
      state.collection = action.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeCollection } = collectionInsights.actions;

export default collectionInsights.reducer;
