import { getAccessToken, isRefreshTokenExpired } from "core/utils";
import { lazy, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  DATA_REVIEW_MODAL,
  LABELING_MODEL,
  USERS,
  LABELED_DATA,
  LABELED_DATA_C,
} from "./constants";

export const protectedRoutes: Array<protectedRouteTypes> = [
  {
    path: DATA_REVIEW_MODAL,
    exact: true,
    component: lazy(() => import("../dateReview")),
  },
  {
    path: LABELING_MODEL,
    exact: true,
    component: lazy(() => import("../labelModel")),
  },
  {
    path: USERS,
    exact: true,
    component: lazy(() => import("../users")),
  },
  {
    path: LABELED_DATA,
    exact: true,
    component: lazy(() => import("../labeledData/posts")),
  },
  {
    path: LABELED_DATA_C,
    exact: true,
    component: lazy(() => import("../labeledData/comments")),
  },
];

export type protectedRouteTypes = {
  path: string;
  component: any;
  exact: boolean;
};

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  useEffect(() => {
    isRefreshTokenExpired();
  }, []);

  if (getAccessToken()) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  return <Route {...rest} render={() => <Redirect to="/login" />} />;
};
