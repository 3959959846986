import { FC } from "react";
import { Formik, FormikConfig, FormikValues } from "formik";

/**
 *
 * @author Muhammad Raheel
 * @category Components
 * @created Jan 13, 2022
 * @description Formik wrapper component to wrap formik input fields
 * @returns This function returns ReactElement
 */

export const FormikWrapper: FC<FormikWrapperPropTypes> = ({ onSubmit, children, initialValues, validationSchema }) => {
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {children}
    </Formik>
  );
};

/**
 * @category Types
 * @author Muhammad Raheel
 * @created Jan 13, 2022
 * @link Formik API Docs: https://formik.org/docs/api/formik
 */

export type FormikWrapperPropTypes = FormikConfig<FormikValues>;
