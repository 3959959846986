import { lazy } from "react";
import { Route } from "react-router-dom";
import { LOGIN_PAGE } from "./constants";

/**
 *
 * @author Muhammad Raheel
 * @category Routes
 * @created Jan 13, 2022
 * @description Array of public routes which serves to AppMain, To define new public route add object in List
 */

export const publicRoutes: Array<publicRouteTypes> = [
  {
    path: LOGIN_PAGE,
    component: lazy(() => import("../UserPages/Login")),
    exact: true,
  },

  // {
  //   path: LANDING_PAGE,
  //   component: lazy(() => import("../LandingPage")),
  //   exact: true,
  // },
];

export type publicRouteTypes = {
  path: string;
  component: any;
  exact: boolean;
};

export const PublicRoute = ({ component: Component, ...rest }: any) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
