import {
  Select,
  Checkbox,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  SelectProps,
  MenuItemProps,
  FormHelperText,
  CircularProgress,
  FormControlProps,
  FormHelperTextProps,
} from "@mui/material";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { FC, ReactNode, useEffect } from "react";
import { useAppSelector } from "redux_config/hooks";

/**
 *
 * @author Muhammad Raheel
 * @category Components
 * @created Jan 13, 2022
 * @description MUI customized select element
 * @returns This function returns ReactElement
 */

export const MuiSelect: FC<
  Partial<MuiSelectPropTypes & SelectProps & FormControlProps & MenuItemProps & FormHelperTextProps>
> = ({
  name,
  label,
  style,
  value,
  error,
  required,
  multiple,
  onChange,
  isCustom,
  helperText,
  serviceName,
  options = [],
  defaultValue,
  fieldKey = "",
  size = "small",
  fieldLabel = "",
  disabled = false,
  fullWidth = true,
  displayNoneOption,
  color = "primary",
  autoWidth = false,
  variant = "outlined",
  ...rest
}) => {
  const { t } = useTranslation();
  const { mode } = useAppSelector((state) => state.theme);
  const { data, isError, isLoading, refetch } = useQuery("DD_source", () => serviceName(), { enabled: false });

  useEffect(() => {
    serviceName && refetch();
    // eslint-disable-next-line
  }, []);

  const errorLabel = () => <span style={{ color: "red" }}>{t("something_went_wrong")}</span>;

  const RenderSelect: FC<ReactNode> = ({ children }) => (
    <Select
      name={name}
      value={value}
      label={label}
      multiple={multiple}
      onChange={onChange}
      autoWidth={autoWidth}
      defaultValue={defaultValue}
      {...rest}
    >
      {children}
    </Select>
  );

  const renderInput = () => {
    if (options.length <= 0) {
      if (isLoading) {
        return (
          <TextField
            fullWidth
            size={size}
            label={label}
            InputProps={{ endAdornment: <CircularProgress size={22} /> }}
          />
        );
      }
      if (isError) {
        return (
          <TextField
            fullWidth
            size={size}
            InputProps={{
              readOnly: true,
              contentEditable: false,
            }}
          />
        );
      }
      if (data?.data.length > 0) {
        return (
          <RenderSelect>
            {displayNoneOption && <MenuItem value="">{t("none")}</MenuItem>}
            {data.data.map((item: any, index: any) => (
              <MenuItem value={item[fieldKey]} key={index}>
                {item[fieldLabel]}
              </MenuItem>
            ))}
          </RenderSelect>
        );
      }
    }
    if (options.length > 0) {
      return (
        <RenderSelect>
          {displayNoneOption && <MenuItem value="">{t("none")}</MenuItem>}
          {options.map((item, index) => (
            <MenuItem value={item[fieldKey]} key={index} disabled={item?.disabled}>
              {
                //@ts-ignore
                multiple && <Checkbox checked={value.indexOf(item[fieldKey]) > -1} />
              }
              {item[fieldLabel]}
            </MenuItem>
          ))}
        </RenderSelect>
      );
    }
  };
  return (
    <FormControl
      size={size}
      color={color}
      error={error}
      variant={variant}
      required={required}
      disabled={disabled}
      fullWidth={fullWidth}
      style={{ ...style, backgroundColor: mode === "light" ? "white" : isCustom ? "" : "#1e1e1e" }}
    >
      <InputLabel>{isError ? errorLabel() : label}</InputLabel>
      {renderInput()}
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

/**
 *
 * @category Types
 * @author Muhammad Raheel
 * @created Jan 13, 2022
 * @link SelectProps API Docs: https://mui.com/api/select/
 * @link FormControlProps API Docs: https://mui.com/api/form-control/
 * @link MenuItemProps API Docs: https://mui.com/api/menu-item/
 * @link FormHelperTextProps API Docs: https://mui.com/api/form-helper-text/
 */

export type MuiSelectPropTypes = {
  fieldKey: string;
  serviceName?: any;
  fieldLabel: string;
  helperText?: string;
  fullWidth?: boolean;
  isCustom?: boolean;
  options?: Array<any>;
  displayNoneOption?: boolean;
};
