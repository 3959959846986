import TFooter from "./tFooter";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { renderCell } from "core/utils";
import EnhancedTableHead from "./tHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { TableSkeleton } from "core/components";
import { useTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import { unstable_batchedUpdates } from "react-dom";
import { useAppSelector } from "redux_config/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import TableContainer from "@mui/material/TableContainer";
import { Box, Checkbox, TableProps, Typography } from "@mui/material";
import React, {
  CSSProperties,
  FC,
  memo,
  MouseEvent,
  useEffect,
  useState,
} from "react";

const MuiTable: FC<Partial<MuiTableProps>> = ({
  data,
  columns,
  loading,
  page = 1,
  orderFunction,
  numOfPages = 1,
  handlePagination,
  sortable = false,
  paginate = false,
  pagination = false,
  tableContainerStyle,
  rowClick,
  multiSelectRow = false,
  selectedArray,
  ...rest
}) => {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [selected, setSelected] = useState<string[]>([]);

  const handleRequestSort = (event: MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    unstable_batchedUpdates(() => {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    });
  };
  const { breakpoints } = useTheme();
  const LandscapeQuery = `${breakpoints.up("xs")} and (orientation: landscape)`;
  const isLandscape = useMediaQuery(LandscapeQuery);
  const isMobile = useMediaQuery(breakpoints.down(430));

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds =
        data && data.length > 0 && data.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    if (selectedArray) {
      selectedArray && selectedArray(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected?.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected?.indexOf(name) !== -1;

  useEffect(() => {
    if (order && orderBy) {
      if (orderFunction) {
        orderFunction(orderBy, order);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy]);
  const RenderRows = () => {
    const { mode } = useAppSelector((state) => state.theme);

    if (data && data.length > 0) {
      return data.map((item: any, index: number) => {
        const isItemSelected = isSelected(item?.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        return (
          <TableRow
            className="t-row-hover"
            key={index}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              "&:hover": {
                backgroundColor: mode === "light" ? "#ffe6c4" : "#4d4d4d",
              },
            }}
          >
            {multiSelectRow && (
              <TableCell
                onClick={(event) => handleClick(event, item.id)}
                padding="checkbox"
              >
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </TableCell>
            )}
            {columns.map(
              (
                {
                  field,
                  render,
                  style,
                  isChild,
                  childFields,
                }: {
                  field: any;
                  render: any;
                  style: any;
                  isChild: boolean;
                  childFields: [];
                },
                i: number
              ) => (
                <TableCell
                  key={i}
                  onClick={() =>
                    rowClick && i !== columns.length - 1
                      ? rowClick(item)
                      : console.log(" ")
                  }
                >
                  {isChild && childFields ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexShrink: 1,
                        flexGrow: 1,
                      }}
                    >
                      {childFields.map((data: any, index: number) => (
                        <Typography
                          ml={-4}
                          key={index}
                          width="12px"
                          fontSize={14}
                          align="center"
                          color={
                            data.includes("sale") && item[data] < 1 ? "red" : ""
                          }
                        >
                          {item[data]}
                        </Typography>
                      ))}
                    </div>
                  ) : (
                    <div style={style ? style : {}}>
                      {renderCell(item, field, render)}
                    </div>
                  )}
                </TableCell>
              )
            )}
          </TableRow>
        );
      });
    }

    return (
      <TableRow>
        <TableCell colSpan={columns.length}>
          <Box sx={{ textAlign: "center" }}>No data found</Box>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Paper elevation={8} sx={{ paddingBottom: "1rem" }}>
      <TableContainer
        style={{
          ...tableContainerStyle,
          minHeight: isLandscape ? "250px" : "",
        }}
      >
        {loading ? (
          <TableSkeleton />
        ) : (
          <Table {...rest} sx={{ minWidth: 650 }} aria-label="simple table">
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={data?.length}
              headCells={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              multiSelectRow={multiSelectRow}
            />
            <TableBody>
              <RenderRows />
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {!loading && pagination ? (
        // <TableFooter className="d-inline-table w-100" sx={{ minWidth: 650, padding: "5px", marginBottom: "1rem" }}>
        <Pagination
          page={page}
          color="primary"
          shape="rounded"
          count={numOfPages}
          variant="outlined"
          siblingCount={isMobile ? 0 : 1}
          boundaryCount={isMobile ? 0 : 1}
          onChange={(evt, value: any) => handlePagination(value)}
          sx={{
            "& .MuiPagination-ul": {
              marginTop: "1rem",
              justifyContent: isMobile ? "flex-start" : "center",
            },
          }}
        />
      ) : // </TableFooter>
      null}

      <div>{!loading && paginate ? <TFooter data={data} /> : null}</div>
    </Paper>
  );
};

type MuiTableProps = {
  data: any;
  columns: any;
  page?: number;
  childFields: [];
  loading: boolean;
  sortable: boolean;
  paginate?: boolean;
  numOfPages?: number;
  pagination?: boolean;
  handlePagination: any;
  multiSelectRow?: boolean;
  tableContainerStyle?: CSSProperties;
  orderFunction: (order: string, orderBy: "asc" | "desc") => void;
  rowClick?: (evt: any) => void;
  selectedArray?: (arr: string[]) => void;
} & TableProps;

export default memo(MuiTable);
