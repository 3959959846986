import { useEffect } from "react";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { REFRESH_TOKEN, TOKEN } from "core/utils/constants";
import { isArray, isObject } from "lodash";

const toastList = new Set();
const MAX_TOAST = 1;
//types written here
type JWTDecodeType = {
  exp: number;
  jti: string;
  token_type: string;
  user_id: number;
};
const intervals = [
  { label: "year", seconds: 31536000 },
  { label: "month", seconds: 2592000 },
  { label: "day", seconds: 86400 },
  { label: "hour", seconds: 3600 },
  { label: "minute", seconds: 60 },
  { label: "second", seconds: 1 },
];

// Password Strength Test Case Generator
export const PasswordStrengthTester = (
  password: string,
  setter: (num: number) => void
) => {
  // eslint-disable-next-line no-useless-escape
  const specialSymbols = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSymbol = specialSymbols.test(password);

  if (password.length > 7) {
    setter(1);
    if (hasLowerCase) {
      setter(1);
    }
    if (hasLowerCase && hasUpperCase) {
      setter(2);
    }
    if (hasLowerCase && hasUpperCase && hasNumber) {
      setter(3);
    }
    if (hasLowerCase && hasUpperCase && hasNumber && hasSymbol) {
      setter(4);
    }
  }

  return {
    hasLowerCase,
    hasUpperCase,
    hasNumber,
    hasSymbol,
  };
};

//get accessToken from localStorage
export const getAccessToken = () => {
  return localStorage.getItem(TOKEN);
};

//get accessToken from localStorage
export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

//clears both refresh and access token also redirects to "/login"
export const clearAppTokensAndRedirect = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);

  window.location.href = window.location.origin + "/login";
};

// Refresh Token Expiry Checker
export const isRefreshTokenExpired = () => {
  const refresh_token = getRefreshToken();
  if (refresh_token) {
    const { exp }: JWTDecodeType = jwtDecode(refresh_token);

    if (
      moment(moment.unix(exp).utc().toISOString()).diff(
        moment.utc(moment.now()).toISOString()
      ) < 0
    ) {
      clearAppTokensAndRedirect();
    }
  }
};

// Table cell genration
export const getDescendantProp = (obj: any, desc: any) => {
  let arr = desc.split(".");
  while (arr.length && (obj = obj[arr.shift()]));
  return obj;
};

export const renderCell = (record: any, field: any, render: any) => {
  if (render) {
    return render(record);
  }

  return record[field];
};

/**
 * @name generateUniqueKey
 * @description
 * It generates the unique identifier for us to use in our application.
 * This will be used for giving unique key property
 * @returns {Generator<string>}
 */
function* generateUniqueKey(): Generator<string> {
  let i = 0;
  while (1) {
    yield `__${i++}__`;
  }
}

/**
 * @name uniqueIdIterator
 * @description
 * Iterator for `generateUniqueKey` function
 */
export const uniqueIdIterator = generateUniqueKey();

export const roundOf = (data: any, digit: number) =>
  `${
    Math.round((data + Number.EPSILON) * Math.pow(10, digit)) /
    Math.pow(10, digit)
  }`;
export const marketCapRound = (data: any) =>
  Number(parseInt(data) / 1000000).toLocaleString(undefined, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

export const timeSince = (date: any) => {
  const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
  const interval: any = intervals.find((i) => i.seconds < seconds);
  const count = Math.floor(seconds / interval.seconds);
  return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
};

export const notify = (type: string, msg: string) => {
  if (toastList.size < MAX_TOAST) {
    let id: any;
    if (type === "success") {
      id = toast.success(msg, {
        onClose: () => toastList.delete(id),
      });
    } else if (type === "error") {
      id = toast.error(msg, {
        onClose: () => toastList.delete(id),
      });
    }
    toastList.add(id);
  }
};
export const ErrorMsg = (err: any) => {
  if (err.response?.data?.detail) {
    return err.response?.data?.detail;
  } else {
    return err.response?.data?.response || err.message;
  }
};

export const useMediaQueryHook = () => {
  return useMediaQuery("(max-width:600px)", { noSsr: true });
};

export const useScript = (url: any) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.type = "application/javascript";
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export const maxValueOfArrayOfObj = (data: any = [], key: string = "") => {
  let max = 0;
  if (data.length > 0) {
    max = Math.max.apply(
      Math,
      data.map((o: any) => o[key])
    );
  }

  if (max >= 0 && max < 0.25) {
    return max + 0.01;
  }
  if (max >= 0 && max < 0.5) {
    const maxF = max + 0.1;
    return maxF;
  } else {
    if (max >= 0 && max < 1) {
      const maxF = max + 0.1;
      return maxF;
    }
  }

  if (max >= 1 && max <= 10) {
    return max + 0.2;
  } else {
    if (max >= 1 && max <= 30) {
      return max + 1;
    }
  }

  return max + 2;
};

export const minValueOfArrayOfObj = (
  data: any,
  key: any,
  excludeZero: boolean = false
) => {
  let min = 0;
  if (data.length > 0) {
    min = Math.min.apply(
      Math,
      data.map((o: any) => o[key])
    );
  }

  if (excludeZero) {
    if (min >= 0 && min < 1) {
      return min;
    }
    return min - 1;
  }

  if (min >= 0 && min < 1) {
    return 0;
  }
  return 0;
};

export const RoundTwoDigit = (num: any) =>
  `${Math.round((num + Number.EPSILON) * 100) / 100}`;

export const openLinkInNewTab = (link: string) => {
  let href = link;
  if (!link.includes("https://") && !link.includes("http://")) {
    href = "https://" + link;
  }

  window && window.open(href, "_blank")?.focus();
};

export const displayErrorMessage = (err: any) => {
  const error = err.response?.data;

  if ((error && error?.length > 0) || Object.keys(error).length > 0) {
    if (isArray(error)) {
      error.map((err: string) => toast.error(err));
    }

    if (isObject(error)) {
      const keys = Object.keys(error);
      keys.map((item: string) => {
        //@ts-ignore
        if (error[item] && error[item].length > 0) {
          //@ts-ignore
          return toast.error(error[item][0]);
        }

        //@ts-ignore
        return toast.error(error[item]);
      });
    }
  } else {
    toast.error(err.response?.data?.message || err.message);
  }
};
