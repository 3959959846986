import React from "react";
import { Grid } from "@mui/material";
import image404 from "core/assets/images/logo/404.svg";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
      <Grid item>
        <img style={{ width: "100%" }} src={image404} alt="404 not found" />
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Link to="/">
            <h3>Go Back Home</h3>
          </Link>
        </div>
      </Grid>
    </Grid>
  );
};

export default Page404;
