import {
  Comment,
  Group,
  PostAdd,
  Preview,
  RateReview,
} from "@mui/icons-material";
import {
  DATA_REVIEW_MODAL,
  LABELING_MODEL,
  USERS,
} from "Pages/routes/constants";
import { TFunctionArg } from "types/global";

export const sidebarList: sidebarListType = (t) => [
  {
    id: 1,
    link: LABELING_MODEL,
    icon: <RateReview htmlColor="#fff" />,
    label: "Labeling Model",
    childList: [],
  },
  {
    id: 2,
    link: DATA_REVIEW_MODAL,
    icon: <Preview htmlColor="#fff" />,
    label: "Data Review Model",
    childList: [],
  },
  {
    id: 3,
    link: USERS,
    icon: <Group htmlColor="#fff" />,
    label: "Users",
    childList: [],
  },
  {
    id: 4,
    icon: <PostAdd htmlColor="#fff" />,
    label: "Labeled Post",
    link: "/labeled-data/posts",
    childList: [],
  },
  {
    id: 5,
    icon: <Comment htmlColor="#fff" />,
    label: "Labeled Comment",
    link: "/labeled-data/comments",
    childList: [],
  },
];

type sidebarListType = (t: TFunctionArg) => {
  id: number;
  icon: any;
  link: string;
  label: string;
  childList: {
    id: number;
    icon: any;
    link: string;
    label: string;
  }[];
}[];
