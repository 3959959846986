import { FC, ReactNode, useState } from "react";
import { useAppSelector } from "redux_config/hooks";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  TextField,
  TextFieldProps,
  IconButton,
  InputAdornment,
  InputAdornmentProps,
} from "@mui/material";

/**
 *
 * @author Muhammad Raheel
 * @category Components
 * @created Jan 13, 2022
 * @description MUI customized input element
 * @returns This function returns ReactElement
 */

export const MuiInput: FC<TextFieldPropTypes> = ({
  icon,
  style,
  label = "",
  type = "text",
  error = false,
  size = "small",
  fullWidth = true,
  required = false,
  disabled = false,
  multiline = false,
  color = "primary",
  variant = "outlined",
  required_field = false,
  autoCompleteOff = false,
  inputProps,
  ...restProps
}) => {
  const { mode } = useAppSelector((state) => state.theme);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const VisibilityIcons = () => (
    <IconButton
      aria-label="toggle password visibility"
      onMouseDown={(e) => e.preventDefault()}
      onClick={() => setShowPassword(!showPassword)}
    >
      {showPassword ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  );

  const RenderIcon: FC<InputAdornmentProps & ReactNode> = ({
    position,
    children,
  }) => <InputAdornment position={position}>{children}</InputAdornment>;

  return (
    <TextField
      size={size}
      error={error}
      color={color}
      variant={variant}
      required={required}
      disabled={disabled}
      multiline={multiline}
      fullWidth={fullWidth}
      type={showPassword ? "text" : type}
      label={`${label}${required_field ? "*" : ""}`}
      style={{
        ...style,
        backgroundColor: mode === "light" ? "white" : "#1e1e1e",
      }}
      InputProps={{
        endAdornment: (
          <RenderIcon position="end">
            <>
              {icon}
              {type === "password" && <VisibilityIcons />}
            </>
          </RenderIcon>
        ),
      }}
      inputProps={
        autoCompleteOff
          ? {
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
            }
          : inputProps
      }
      {...restProps}
    />
  );
};

/**
 * @category Types
 * @author Muhammad Raheel
 * @created Jan 13, 2022
 * @link TextFieldProps API Docs: https://mui.com/api/text-field/
 */

export type TextFieldPropTypes = {
  icon?: ReactNode;
  required_field?: boolean;
  autoCompleteOff?: boolean;
} & TextFieldProps;
