import { createSlice } from "@reduxjs/toolkit";

type InitialStateType = { walletConnected: boolean };

const walletStatus = () => {
  if (localStorage.getItem("isWalletConnected") === "true") {
    return true;
  }
  return false;
};

const initialState: InitialStateType = { walletConnected: walletStatus() };

export const walletSlicer = createSlice({
  name: "walletStatus",
  initialState,
  reducers: {
    changeWalletStatus: (state, action) => {
      state.walletConnected = action.payload;
      return state;
    },
  },
});

export const { changeWalletStatus } = walletSlicer.actions;

export default walletSlicer.reducer;
