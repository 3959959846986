import { createSlice } from "@reduxjs/toolkit";

type InitialStateType = {
  radioValue: { key: string; value: string | number };
  collections: never[];
};

const initialState: InitialStateType = {
  radioValue: { key: "perct_floor", value: 60 },
  // @ts-ignore
  collections: JSON.parse(localStorage.getItem("comp_collections")) || [],
};

export const comparisonRadio = createSlice({
  name: "radioValue",
  initialState,
  reducers: {
    changeRadioValue: (state, action) => {
      state.radioValue = action.payload;
      return state;
    },
    changeCollections: (state, action) => {
      state.collections = action.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeRadioValue, changeCollections } = comparisonRadio.actions;

export default comparisonRadio.reducer;
